<template>
  <div id="loading-bg">
      <div class="loading-logo">
        <img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/logoescrita_oraculo_02.svg">
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
export default {
  props: {
    commissions: Boolean,
  },
  setup() {
    return { toast: useToast() }
  },
  mounted() {
    this.redirect()
  },
  methods: {
    ...mapActions({
      getAppifyLink: types.GET_APPIFY_LINK
    }),
    redirect() {
      let to = {};
      if(this.commissions){
        to = { redirect: 'grid_view_commissions' }
      }
      this.getAppifyLink(to)
        .then(response => {
          window.location.replace(response.data.link)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao abir o sistema. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>
